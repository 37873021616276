import React from 'react'

import styled, { keyframes } from 'styled-components'
import { hinge } from 'react-animations'
import Layout from '../components/Layout'

const hingeAnimation = keyframes`${hinge}`

const NoRoute = () => {
  return (
    <Layout>
      <DNEText>Oops! Page does not exist...</DNEText>
    </Layout>
  )
}

export default NoRoute

const DNEText = styled.h1`
  animation-delay: 2s;
  animation: 3s ${hingeAnimation};
`
