import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 240
const navItems = ['Forms']
const mobileNave = [{ title: 'Citation Resolution', link: '/citation-resolution-form' }]

const Header = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <LogoWrapperMobile className='logo-link-wrapper-mobile' style={{ padding: '2%' }}>
        <Link to={'/'}>
          <img style={{ width: '90%' }} src='https://getcheckapp.com/wp-content/uploads/elementor/thumbs/Check-Logo-White-pu6zdd5lxecpad1jz32kldv8ahml30l3rziaqkszr4.png' />
        </Link>
      </LogoWrapperMobile>
      <Divider />
      <List>
        {mobileNave.map((item: { title: string; link: string }, index: number) => (
          <Link to={item.link} key={index} style={{ textDecoration: 'none' }}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined
  return (
    <HeaderWrapper>
      <AppBar component='nav' className='mui-app-bar-wrapper' style={{ padding: '15px 8px' }} position='static'>
        <Toolbar>
          <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' }, margin: '15px' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, width: '30%' }}>
            <div className='logo-link-wrapper'>
              <Link to={'/'}>
                <img id='chk-header-logo' src='https://getcheckapp.com/wp-content/uploads/elementor/thumbs/Check-Logo-White-pu6zdd5lxecpad1jz32kldv8ahml30l3rziaqkszr4.png' />
              </Link>
            </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button id='form-button' aria-controls={open ? 'forms-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
              Forms
            </Button>
            <Menu
              id='forms-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'forms-button',
              }}
            >
              <Link to='/citation-resolution-form' style={{ textDecoration: 'none' }}>
                <MenuItem onClick={handleClose}>Citation Form</MenuItem>
              </Link>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.div`
  .mui-app-bar-wrapper {
    background-color: #3626a7;
    background-image: linear-gradient(130deg, #1a1386 0%, #5271ff 100%);
    width: 100vw;
    height: 75px;
    display: flex;
    justify-content: center;
  }

  .logo-link-wrapper {
    margin-left: 2%;

    #chk-header-logo {
      width: 200px;
      border: 2px solid #fff;
      padding: 5px;
      border-radius: 10px;
    }
  }

  #form-button {
    color: #fff;
    font-size: 18px;
  }
`
const LogoWrapperMobile = styled.div`
  background-color: #3626a7;
  background-image: linear-gradient(130deg, #1a1386 0%, #5271ff 100%);
  padding: 3%;
`
