import React, { FC } from 'react'
import styled from 'styled-components'

type LayoutProps = {
  children: any
  pageTitle?: string
}

const Layout = ({ children, pageTitle }: LayoutProps) => {
  return <LayoutWrapper>{children}</LayoutWrapper>
}

export default Layout

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 50px;
  text-align: center;
`
