import React, { useEffect, useState } from 'react'

const useDocumentTitle = (title: string) => {
  const [documentTitle, setDoucmentTitle] = useState(title)
  useEffect(() => {
    document.title = `Check App - ${title ? title : 'Forms'}`
  }, [documentTitle])

  return [documentTitle, setDoucmentTitle]
}

export { useDocumentTitle }
