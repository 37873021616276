const baseUrl = 'api'

const utilityRoutes = {
  enrollment: {
    enrollment: `/${baseUrl}/enrollment/`,
  },
  stripe: {
    createPaymentIntent: `/${baseUrl}/stripe/create-payment-intent`,
  },
}

export default utilityRoutes
