import axios from 'axios'

const utilityBaseUrl = process.env.REACT_APP_UTILITY_SERVICE_URL_PROD || 'http://localhost:4000'

const utilityService = axios.create({
  baseURL: utilityBaseUrl,
  timeout: 10000,
})

export default { axios, utilityService }
