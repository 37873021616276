import React, { useEffect, useState } from 'react'
import { routes } from './routes'
import { Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import styled from 'styled-components'

function App() {
  return (
    <MainAppWrapper>
      <Header />
      <Routes>
        {routes.map((route, index: number) => {
          return <Route path={route.routePath} element={<route.component />} />
        })}
      </Routes>
      <Footer />
    </MainAppWrapper>
  )
}

export default App

const MainAppWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`
