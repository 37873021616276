import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { bounceInLeft } from 'react-animations'
import Layout from '../components/Layout'
import Button from '@mui/material/Button'

const bounceAnimation = keyframes`${bounceInLeft}`

const ThankYou = () => {
  const location = useLocation() as any
  const navigate = useNavigate()

  useEffect(() => {
    // protect from unauthorized user navigation here
    const prevLocation = location.state?.prevRoute
    const paymentSuccessful = location.state?.paymentSuccessful
    if (prevLocation === '/citation-resolution-form' && paymentSuccessful) {
      return
    } else {
      navigate('/') // If unauthorized
    }
  }, [location])

  return (
    <Layout>
      <ThankYouWrapper>
        <h1 className='thank-you-text'>
          Thank You for choosing <span id='check-text'>CHECK</span>
        </h1>
        <h2 className='thank-you-text-message'>We will be In contact soon.</h2>
        <div className='center-content-wrapper'>
          <img className='animate__animated animate__bounc animate__delay-2s' alt='handshake' src='https://getcheckapp.com/wp-content/uploads/2021/02/Consent-First-1024x886.png' />
        </div>
        <div className='btn-content-wrapper'>
          <a href='https://getcheckapp.com'>
            <Button variant='check'>Check Home</Button>
          </a>
        </div>
      </ThankYouWrapper>
    </Layout>
  )
}

export default ThankYou

const ThankYouWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  width: 100%;

  .thank-you-text {
    margin: 0;
    font-size: clamp(1rem, 5.5vw, 3rem);
    #check-text {
      color: #3626a7;
      font-weight: 800;
      @media (max-width: 594px) {
        font-size: 3rem;
      }
    }

    @media (max-width: 594px) {
      font-size: 2rem;
      margin-bottom: 4%;
    }
  }

  .thank-you-text-message {
    margin: 0;
    font-size: clamp(1rem, 5.5vw, 2.5rem);
    font-weight: 400;
  }
  .center-content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 40%;
      animation: 1.5s ${bounceAnimation};
      @media (max-width: 594px) {
        width: 60%;
      }
    }
  }

  .btn-content-wrapper {
    margin-top: 5%;
    a {
      text-decoration: none;
      button {
        font-size: 20px;
      }
    }
  }
`
