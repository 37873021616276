import { IRoute } from './route'
import Home from '../pages/Home'
import CitationResolution from '../pages/CitationResolution'
import ThankYou from '../pages/ThankYou'
import NoRoute from '../pages/NoRoute'

export const routes: Array<IRoute> = [
  {
    routePath: '/',
    component: Home,
    protected: false,
    exact: true,
  },
  {
    routePath: '/citation-resolution-form',
    component: CitationResolution,
    protected: false,
    exact: false,
  },
  {
    routePath: '/thank-you',
    component: ThankYou,
    protected: false,
    exact: false,
  },
  {
    routePath: '/*',
    component: NoRoute,
    protected: false,
    exact: false,
  },
]
