import React, { FC, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import checkLogo from '../../assets/images/checkLogo.png'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'

interface ILegalDoc {
  name: string
  dateAccepted: Date
  versionAccepted: string
  accepted: boolean
}
export interface ICustomer {
  firstName: string
  lastName: string
  dateOfBirth: Date
  email: string
  phoneNumber: string
  citationNumber: number
  citationImage: any
  isMarketingOpted: boolean
  legalDocAccepted: boolean
}

type CitationFormProps = {
  customerData: ICustomer | null
  setCustomerData: (value: any) => void
  formComplete: boolean
  setFormComplete: (value: boolean) => void
}

const CitationResolutionFrom = ({ customerData, formComplete, setFormComplete, setCustomerData }: CitationFormProps) => {
  const { register, setValue, getValues, getFieldState, watch, formState, handleSubmit } = useForm<ICustomer>({ mode: 'onBlur' })
  const { errors, isValid } = formState
  const phoneNum = watch('phoneNumber')

  useEffect(() => {
    phoneNum && formatPhoneNumber(phoneNum)
  }, [phoneNum])

  const formatPhoneNumber = (phoneNum: string) => {
    const formatedNumber = phoneNum
      .split('')
      .map((char, index) => {
        if (index === 0 && /^[0-9]$/.test(char)) {
          return `(${char}`
        }
        if (index === 4 && /^[0-9]$/.test(char)) {
          return `) ${char}`
        }
        if (index === 9 && /^[0-9]$/.test(char)) {
          return `-${char}`
        }
        return char
      })
      .join('')
    setValue('phoneNumber', formatedNumber)
  }

  const isFileAvailable = () => {
    const files = ((document.getElementById('filesInput') as any) || {}).files || {}
    return Object.keys(files).length > 0
  }

  const submit = async (data: ICustomer) => {
    const files = (document.getElementById('filesInput') as any).files
    const formData = new FormData()
    formData.set('firstName', data.firstName)
    formData.set('lastName', data.lastName)
    formData.set('dateOfBirth', data.dateOfBirth as any)
    formData.set('email', data.email)
    formData.set('phoneNumber', data.phoneNumber)
    formData.set('citationNumber', data.citationNumber as any)
    formData.set('isMarketingOpted', data.isMarketingOpted as any)
    formData.set('legalDocsAccepted', data.legalDocAccepted as any)

    Object.keys(files).forEach((key: any) => {
      formData.append(files.item(key).name, files.item(key))
    })
    setCustomerData(formData)
    setFormComplete(true)
  }

  return (
    <Box style={{ width: '100%', maxWidth: '850px', padding: '3% 4%', display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: '5px', boxShadow: '0 5px 12px 0 rgba(0,0,0,0.3)', backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={checkLogo} alt='check logo' style={{ width: '60%' }} />
        <Typography variant='h2' style={{ fontSize: 'clamp(1rem, 7.0vw, 4rem)', fontWeight: 500 }}>
          Citation Resolution
        </Typography>
        <Typography variant='h5' style={{ fontSize: 'clamp(1rem, 2.0vw, 1.8rem)' }}>
          To help us get the process started on your citation resolution please fill out the form below.
        </Typography>
      </div>
      <Box
        component='form'
        style={{ width: '100%', padding: '2%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(submit)}
      >
        <FieldsWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField className='chk-input-field' error={errors.firstName?.type === 'required'} label='First Name' {...register('firstName', { required: true })} />
              <span id='check-icon'>{!errors.firstName && getFieldState('firstName').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.firstName?.type === 'required' && <ErrorText>*First name required</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField className='chk-input-field' error={errors.lastName?.type === 'required'} label='Last Name' {...register('lastName', { required: true })} />
              <span id='check-icon'>{!errors.lastName && getFieldState('lastName').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.lastName?.type === 'required' && <ErrorText>*Last name required</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField type='date' className='chk-input-field' error={errors.dateOfBirth?.type === 'required'} helperText='Date Of Birth' {...register('dateOfBirth', { required: true })} />
              <span id='check-icon'>{!errors.dateOfBirth && getFieldState('dateOfBirth').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.dateOfBirth?.type === 'required' && <ErrorText>*Date of birth required</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField className='chk-input-field' label='Email' error={errors.email?.type === 'pattern' || errors.email?.type === 'required'} {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })} />
              <span id='check-icon'>{!errors.email && getFieldState('email').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.email?.type === 'required' && <ErrorText>*Email required</ErrorText>}
            {errors.email?.type === 'pattern' && <ErrorText>*Not a valid email</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField className='chk-input-field' error={errors.phoneNumber?.type === 'required' || errors.phoneNumber?.type === 'pattern'} label='Phone Number' {...register('phoneNumber', { required: true, pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ })} />
              <span id='check-icon'>{!errors.phoneNumber && getFieldState('phoneNumber').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.phoneNumber?.type === 'required' && <ErrorText>*Phone number required</ErrorText>}
            {errors.phoneNumber?.type === 'pattern' && <ErrorText>*Not a valid phone number</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField className='chk-input-field' error={errors.citationNumber?.type === 'required'} label='Citation Number' {...register('citationNumber', { required: true })} />
              <span id='check-icon'>{!errors.citationNumber && getFieldState('citationNumber').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.citationNumber?.type === 'required' && <ErrorText>*Citation number required</ErrorText>}
          </InputWrapper>
          <InputWrapper>
            <div className='input-check-wrapper'>
              <TextField type='file' id='filesInput' {...register('citationImage', { required: true, validate: { validateFile: (v) => isFileAvailable() } })} />
              <span id='check-icon'>{!errors.citationImage && getFieldState('citationImage').isTouched && <CheckIcon style={{ color: 'green' }} />}</span>
            </div>
            {errors.citationNumber?.type === 'required' && <ErrorText>*Citation image required</ErrorText>}
          </InputWrapper>
        </FieldsWrapper>
        <InputWrapper>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked {...register('isMarketingOpted', { required: false })} />} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} label='Allow CHECK to alert me of future deals and news.' />
            <CheckBoxCntrlWrapper>
              <FormControlLabel control={<Checkbox {...register('legalDocAccepted', { required: true })} />} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} label='Agree to' />
              <p>
                <a href={`/assets/legal-docs/terms-and-conditions.pdf`} title='Click here to view Terms and Conditions' target='_blank' rel='noreferrer'>
                  Terms &amp; Conditions
                </a>
              </p>
            </CheckBoxCntrlWrapper>
          </FormGroup>
        </InputWrapper>
        <Button type='submit' disabled={!isValid} variant='check' style={{ width: '275px', height: '50px', marginTop: '5%', fontSize: '22px' }}>
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default CitationResolutionFrom

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InputWrapper = styled.div`
  width: 100%;
  margin-top: 2%;

  .input-check-wrapper {
    display: flex;
    align-items: baseline;

    .chk-input-field {
      width: 95%;
    }

    .check-icon {
      width: 5%;
    }
  }
`

const CheckBoxCntrlWrapper = styled.span`
  display: flex;
  p {
    margin-left: -12px;

    a {
      text-decoration: none;
      font-size: 18px;
      font-weight: 600;
      margin-top: 15px;
    }
  }
`

const ErrorText = styled.p`
  color: crimson;
  margin: 0 2%;
`
