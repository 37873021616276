import React, { FC } from 'react'
import Layout from '../components/Layout'
import SC, { keyframes } from 'styled-components'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import checkLogo from '../assets/images/checkLogo.png'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import { rubberBand } from 'react-animations'
const rubberBandAnimation = keyframes`${rubberBand}`

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#3626a7',
  backgroundImage: 'linear-gradient(130deg,#1a1386 0%,#5271ff 100%)',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: '#fff',
  minHeight: '300px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

const Home: FC = () => {
  useDocumentTitle('Home')
  return (
    <Layout>
      <Box sx={{ flexGrow: 1, width: '90%' }} style={{ padding: '0 2% 2% 2%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item style={{ minHeight: '200px' }} elevation={15}>
              <UpperContentWrapper>
                <div className='chk-title-wrapper'>
                  <div id='chk-logo-hero-wrapper'>
                    <img src={checkLogo} alt='check logo' />
                  </div>
                  <span>We offer an</span>
                  <p>All-in-One, End-to-End Solution</p>
                  <span>for citation resolution.</span>
                  <br />
                  <Link to='/citation-resolution-form'>
                    <button>GET STARTED NOW</button>
                  </Link>
                </div>
                <div className='chk-upper-img-container'>
                  <span id='chk-ticket-txt'>Got A Traffic Ticket?</span>
                  <img src='https://getcheckapp.com/wp-content/uploads/2022/05/Shutterstock_1936735255.png' alt='phone' />
                </div>
              </UpperContentWrapper>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item elevation={15}>
              <img src='https://getcheckapp.com/wp-content/uploads/2021/02/Payment-1-1-150x150.png?crop=1' alt='step 1' />
              <Typography variant='h4'>Step 1</Typography>
              <Typography variant='h6'>Provide Check your traffic ticket information.</Typography>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item elevation={15}>
              {' '}
              <img src='https://getcheckapp.com/wp-content/uploads/2021/02/Consent-First-150x150.png?crop=1' alt='step 2' />
              <Typography variant='h4'>Step 2</Typography>
              <Typography variant='h6'>Obtain and decide on the appropriate course of action.</Typography>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item elevation={15}>
              <img src='https://getcheckapp.com/wp-content/uploads/2021/02/Motorists-150x150.png?crop=1' alt='step 3' />
              <Typography variant='h4'>Step 3</Typography>
              <Typography variant='h6'>Check will handle the rest. It's that simple!</Typography>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default Home

const UpperContentWrapper = SC.div`
  display: flex;

  @media(max-width:594px){
    flex-direction:column;

  }
  .chk-title-wrapper{
    width: 60%;

    @media(max-width:594px){
      width:100%;
    }

    #chk-logo-hero-wrapper{
        margin-bottom:2%;

        img{
          width:50%;
          box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
          border-radius:8px;
        }
    }

    h1{
      font-size:clamp(2rem, 4vw, 7rem);
      @media(max-width:594px){
        font-size:50px;
      }
    }

    span{
      font-size:clamp(2rem, 2vw, 5rem);
    }

    p{
      font-size:clamp(2rem, 3vw, 7rem);
      margin:0;
    }

    button{
      width: 350px;
       height: 50px;
        margin-top: 2%;
        background-color: #d22024;
        color: #fff;
        border:none;
        border-radius:8px;
        font-size:22px;
        cursor:pointer;
        transition:all .5s ease;
        animation: 1s ${rubberBandAnimation};
        :hover{
          box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
          background-color: #e43639;
        }
    }
  }

  .chk-upper-img-container{
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    width: 40%;
    border-radius:8px;
    background-image: url("https://getcheckapp.com/wp-content/uploads/2022/05/shutterstock_508982365.jpg");

    @media(max-width:594px){
      width:100%;
      margin-top:3%;
    }

   #chk-ticket-txt{
    position:absolute;
    right:20px;
    top:10px;
    color:#d22024;
    font-weight:700;
    font-size: 1.5rem;
    }

    img{
      width: 80%;

      @media(max-width:594px){
        width:100%;
      }

    }
  }

`
