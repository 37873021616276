import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ICustomerCreationRes } from '../../pages/CitationResolution';
import Layout from '../Layout';

type StripePayment = {
  customerInfo: ICustomerCreationRes | null;
};

const BaseURL =
  process.env.REACT_APP_UTILITY_SERVICE_URL_PROD || 'http://localhost:4000';

export default function StripePayment({ customerInfo }: StripePayment) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            setMessage('Your payment was not successful, please try again.');
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/thank-you`,
      },
      redirect: 'if_required',
    });
    setIsLoading(false);

    if (response.paymentIntent) {
      // Call our utility endpoint and update the customers citation record with the payment Id. We can Also set the payment status of the citation to paid We need to pass back the customer {id, citationNumber || citationId, paymentId }
      const dbRes = await axios.put(
        `${BaseURL}/api/citation/${customerInfo?.customerId}/${customerInfo?.citationId}`,
        { paymentServiceId: response.paymentIntent?.id, paymentStatus: 'PAID' }
      );
      console.log(dbRes);
      // We can also trigger payment emails reciepts from out end or stripe
      // Then redirect to thank you page, but pass the customer first and last name in the router props.
      navigate('/thank-you', {
        state: { paymentSuccessful: true, prevRoute: location.pathname },
      });
    } else if (
      response.error?.type === 'card_error' ||
      response.error?.type === 'validation_error'
    ) {
      setMessage(response.error?.message);
    } else {
      setMessage('An unexpected error occurred.');
    }
  };

  return (
    <Layout>
      <PaymentWrapper>
        <Typography
          variant="h2"
          style={{ fontWeight: 500, margin: '2% 0 5% 0' }}
        >
          Payment
        </Typography>
        <form
          id="payment-form"
          style={{ width: '80%' }}
          onSubmit={handleSubmit}
        >
          <PaymentElement id="payment-element" />
          <Button
            variant="check"
            disabled={isLoading || !stripe || !elements}
            id="submit"
            type="submit"
          >
            <span className="pay-btn" id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                'PAY NOW'
              )}
            </span>
          </Button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </PaymentWrapper>
    </Layout>
  );
}

const PaymentWrapper = styled.div`
  width: 100%;
  max-width: 850px;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3% 5%;
  border-radius: 5px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.02);

  #payment-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    #payment-element {
      width: 100%;
    }

    #submit {
      width: 300px;
      height: 45px;
      margin: 8% 0 2% 0;
      font-size: 18px;
    }
  }
`;
