import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  const date = new Date(Date.now()).getFullYear()
  return <FooterWrapper>Check&copy; all rights reserved {date}</FooterWrapper>
}

export default Footer

const FooterWrapper = styled.div`
  width: 100vw;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: absolute;
`
