import React, { FC, useEffect, useState } from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ICustomer } from '../components/citation-resolution/CitationResolutionForm';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Layout from '../components/Layout';
import CitationResolutionFrom from '../components/citation-resolution';
import StripePayment from '../components/stripe-payment';
import FormStepper from '../components/form-stepper';
import Check from '../components/check-mark/Check';
import {
  createEnrollmentAsync,
  createPaymentIntentAsync,
} from '../service/httpService';

const STRIPE_API_KEY =
  'pk_live_51Kbx5hBfeguksCtCpj9v9nPw9GSROubJUwt18287ptzNM15RhesV1HFWQJGEf0qbaHey1iI9QunKeoSeewrhh5yn00ihTPzEBJ' ||
  'pk_test_51Kbx5hBfeguksCtC07pT9LzDz9gt2o3qNR811ezOG085OXoWfNIzD0YCXmfjhUsfAbZkYC6csdX1WQsgD4IUzhrH00SGvP0JMc'; // Stripe JS need a Publishable Key as API's need Private Key
const productObj = { id: 'prod_MPaFnpfOPjbJzw', amount: 4999 };
const testProductObj = { id: 'prod_MaIltm09uVYXsF', amount: 100 };

const stepsMetaData = [
  {
    title: 'Citation Form',
  },
  {
    title: 'Payment',
  },
];

export interface ICustomerCreationRes {
  customerId: string;
  citationId: string;
}

const CitationResolution: FC = () => {
  useDocumentTitle('Citation Resolution');
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [errorMesage, setErrorMesage] = React.useState<string | null>(null);
  const [successMesage, setSuccessMesage] = React.useState<string | null>(null);
  const [showCheckIcon, setShowCheckIcon] = React.useState<boolean>(false);
  const [isValidationError, setIsValidationError] =
    React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [customerInfo, setCustomerInfo] = React.useState<ICustomer | null>(
    null
  );
  const [citationFormComplete, setCitationFormComplete] =
    React.useState<boolean>(false);
  const [formSubmissionRes, setFormSubmissionRes] =
    React.useState<ICustomerCreationRes | null>(null);
  const stripePromise = loadStripe(STRIPE_API_KEY); // TODO: LOAD IN ENV VARS
  //STRIPE
  const [clientSecret, setClientSecret] = useState('');
  const [sessionPaymentIntent, setSesionPaymentIntent] = useState<string>('');

  React.useEffect(() => {
    citationFormComplete && setFormDataAsync();
  }, [citationFormComplete]);

  const setFormDataAsync = async () => {
    setShowLoading(true);
    setErrorMesage(null);
    setSuccessMesage(null);

    // Create Stripe PaymentIntent
    let pIIRes = null;
    try {
      pIIRes = await createPaymentIntentAsync({ products: [testProductObj] });
      if (pIIRes.status !== 201)
        throw new Error('Payment Intent Could not be created');
      setClientSecret(pIIRes.data.clientSecret);
      setSesionPaymentIntent(pIIRes.data.id);
    } catch (error: any) {
      const msg =
        'An error occured saving your information. Please contact Check if this problem persists.';
      setShowLoading(false);
      setIsValidationError(true);
      error.message && setErrorMesage(`${msg} ${error.message}`);
      return;
    }

    // Create Customer
    let enrollResponse: ICustomerCreationRes | any | null = null;
    try {
      enrollResponse = await createEnrollmentAsync(
        pIIRes.data.id,
        customerInfo
      );
      if (enrollResponse.status !== 201)
        throw new Error(enrollResponse.response.data);
      setFormSubmissionRes(enrollResponse.data.customer);
      setShowLoading(false);
      setIsValidationError(false);
      setShowCheckIcon(true);
      setSuccessMesage('Success!');
    } catch (error: any) {
      const msg =
        'An error occured saving your information. Please contact Check if this problem persists.';
      setShowLoading(false);
      setIsValidationError(true);
      error.message && setErrorMesage(`${msg} ${error.message}`);
      return;
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setShowCheckIcon(false);
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextEnabled = (): boolean => {
    switch (activeStep) {
      case 0:
        return citationFormComplete;
      case 1:
        return false;
      default:
        return false;
    }
  };

  //STRIPE
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  //STRIPE

  return (
    <Layout>
      <FormWrapper>
        <StepContainer>
          <FormStepper
            stepsMetaData={stepsMetaData}
            activeStep={activeStep}
            handleReset={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </StepContainer>

        <ContentWrapper>
          {activeStep === 0 ? (
            <CitationResolutionFrom
              customerData={customerInfo}
              setCustomerData={(value: any) => setCustomerInfo(value)}
              formComplete={citationFormComplete}
              setFormComplete={(value: boolean) => {
                setCitationFormComplete(value);
              }}
            />
          ) : (
            clientSecret && (
              <Elements options={options as any} stripe={stripePromise}>
                <StripePayment customerInfo={formSubmissionRes} />
              </Elements>
            )
          )}

          {showLoading && (
            <>
              <LinearProgress style={{ width: '700px', minWidth: '300px' }} />
              <h3 style={{ margin: '12px 0 0 0' }}>Saving Your Data...</h3>
            </>
          )}

          {activeStep === 0 && errorMesage && (
            <ErrorMessage>{errorMesage}</ErrorMessage>
          )}
          {activeStep === 0 && successMesage && (
            <SuccessMessage>{successMesage}</SuccessMessage>
          )}
          <Check show={showCheckIcon} />
          {activeStep !== 1 && (
            <Box
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '5%',
              }}
            >
              {/* <Button className='navButton' id='backBtn' variant='check' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button> */}

              <Button
                className="navButton"
                id="nextBtn"
                variant="check"
                onClick={handleNext}
                disabled={!handleNextEnabled() || isValidationError}
              >
                {activeStep === stepsMetaData.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          )}
        </ContentWrapper>
      </FormWrapper>
    </Layout>
  );
};

export default CitationResolution;

const FormWrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 595px) {
    width: 90%;
  }

  .navButton {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }

  .navButton#backBtn {
    border-radius: 30px 0 0 30px;
  }
  .navButton#nextBtn {
    border-radius: 30px 30px 30px 30px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  margin: 5% 0 2% 0;
  width: 55%;

  @media (max-width: 595px) {
    width: 100%;
  }
`;
const SuccessMessage = styled.p`
  color: #8dc63f;
  max-width: 700px;
  font-size: 20px;
  margin: 1% 0 0 0;
  font-weight: 500;
  text-align: center;
`;

const ErrorMessage = styled(SuccessMessage)`
  color: crimson;
`;

const StepContainer = styled.div`
  width: 40%;

  @media (max-width: 595px) {
    width: 80%;
  }
`;
