import http from '../config/http/http-base-urls'
import utilityRoutes from '../config/http/utility-api-urls'

export const createPaymentIntentAsync = async (products: any) => {
  try {
    return await http.utilityService.post(`${utilityRoutes.stripe.createPaymentIntent}`, products)
  } catch (error: any) {
    return error
  }
}

export const createEnrollmentAsync = async (paymentIID: string, customer: any) => {
  try {
    return await http.utilityService.post(`${utilityRoutes.enrollment.enrollment}${paymentIID}`, customer)
  } catch (error: any) {
    return error
  }
}
