import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import Check from '@mui/icons-material/Check'
import { StepIconProps } from '@mui/material/StepIcon'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 20,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#3626A7',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#3626A7',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className='QontoStepIcon-completedIcon' /> : <div className='QontoStepIcon-circle' />}
    </QontoStepIconRoot>
  )
}

type FormStepperProps = {
  stepsMetaData: Array<{ title: string }>
  activeStep: number
  handleReset: () => void
}

const FormStepper = ({ stepsMetaData, activeStep, handleReset }: FormStepperProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {stepsMetaData.map((step, index: number) => {
          const stepProps: { completed?: boolean } = {}
          return (
            <Step key={index} {...stepProps}>
              <StepLabel StepIconComponent={QontoStepIcon}>{step.title}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

export default FormStepper
