import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    check: true
  }
}

export const appTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'check' },
          style: {
            textTransform: 'uppercase',
            backgroundColor: '#3626A7',
            backgroundImage: 'linear-gradient(130deg, #1a1386 0%, #5271ff 100%)',
            color: '#fff',
          },
        },
      ],
    },
  },
})
